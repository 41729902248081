/* General CSS */

:root {
  --clr-neutral-900: hsl(207, 19%, 9%);
  --clr-neutral-100: hsl(0, 0%, 100%);
  --clr-accent-400: #fb6930;
  --columns:2;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0px;
  padding: 0px;
  font-family: Montserrat;
  box-sizing: border-box;
}
h2 {
  text-align: center;
  text-decoration: underline;
}
img {
  max-width: 100%;
  display: block;
}
::selection {
  background-color: #ffc26c;
}

/* App.jsx */
.App{
  max-width: 1320px;
  margin: 0 auto;
}

/* Navbar */

.Navbar {
  width: 100%;
  padding: 0;
  font-weight: 500;
  justify-content: space-around;
}
.navbar-sticky {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
}
.navbar-logo{
  display:inline-block;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  margin: 0;
}
.navbar-brand{
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
  margin: 0 0 0 25px;
  margin-right: 25%;
}
.navbar-logo img{
  max-width: 100%;
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  width: 120px;
  height: 50px;
  
}

/* Intro */
.info{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.container {
  text-align: center;
  display: grid;
  grid-auto-flow: column;
  margin: 0 auto;
}
:where(.flow) {
  margin-top: var(--flow-spacer, 4em);
}
.even-column {
  display: grid;
  gap: 1em;
  margin-top: 4%;
  text-align: center;
}

.scroll {
  visibility: hidden;
}

@media (min-width: 990px) {
  .even-column {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }

  /* .scroll {
    visibility: visible;
  } */
  /* .scroll img {
    display: inline;
  } */
}
.even-column h1 {
  font-weight: 800;
  font-size: 58px;
  line-height: 80px;
  color: #252b42;
  letter-spacing: 0.2px;
  text-align: center;
}
.even-column p {
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  color: #737373;
  padding: 10px;
  letter-spacing: 0.2px;
  text-align: center;
  margin-bottom: 26px;
}
.illustration {
  width: 688px;
  height: 443px;
}

/* About */

.about {
  text-align: center;
  margin: 8%;
}

.about h1 {
  text-decoration: underline;
  text-decoration-thickness: 2px;

  color: #252b42;

  font-weight: 600;
  line-height: 80px;
  letter-spacing: 0.2px;
}

.about p {
  color: #000;
  line-height: 38px;
  font-weight: 400;
  letter-spacing: 0.2px;
  font-size: 20px;
}

/* Team */

#team {
  text-decoration: none;
}

/* .grid-container {
  background-color: rgba(217, 217, 217, 1);
  border-radius: 23px;
  position: relative;
  margin: 1% 15% 10%;
  padding: 1% 1%;
} */
.grid-container img {
  width: 100%;
}
@media screen and (max-width: 1320px) {
  .grid-container {
    background-color: rgba(217, 217, 217, 1);
    border-radius: 23px;
    position: relative;
    margin: 1% 8% 1%;
    padding: 1% 1%;
  }
  /* .sub-grid-container{
    grid-template-columns: repeat(auto-fit, minmax(calc(100% / var(--columns) - 20px), 1fr));
  } */
}
@media screen and (min-width: 1320px) {
  .grid-container {
    background-color: rgba(217, 217, 217, 1);
    border-radius: 23px;
    position: relative;
    margin: 1% 8% 10%;
    padding: 1% 1%;
  }

}

.sub-grid-container {
  display: flex;
  flex-wrap: wrap;
  border-radius: 23px;
  justify-content: space-around;
  /* grid-template-columns: repeat(auto-fit, minmax(calc(100% / var(--columns) - 20px), 1fr)); */
}

.grid-item {
  margin: 1%;
  border-radius: 23px;
  /* position:relative ; */
  height: 200px;
  width: 200px;
  background-color: #fff;
  display: flex;
  justify-content: center;
}

@media (max-width: 50rem) {
  .grid-item {
    width: 100px;
    height: 100px;
    justify-content: initial;
  }
  .button {
    visibility: hidden;
  }
  .card {
    word-wrap: normal;
  }
  .card-content {
    background: none !important;
  }
}

/* Card  */

.card {
  background-size: cover;
  border-radius: 23px;
  overflow: hidden;
  text-align: center;
  transition: transform 500ms ease;
  background-image: url();
}
.card:hover,
.card:focus-within {
  transform: scale(1.03);
}
.card-content {
  position: absolute;

  width: 100%;

  --padding: 1.5rem;
  padding: var(--padding);
  background: linear-gradient(hsl(0 0% 0% /0), hsl(0 0% 0% /0.4));
}
.card-title {
  color: #fff;
}
.card:hover .card-title::after,
.card:focus-within .card-content::after {
  transform: scaleX(1);
}

@media (hover) {
  .card-content {
    transform: translateY(90%);
    transition: transform 500ms ease;
  }
  .card:hover .card-content,
  .card:focus-within .card-content {
    transform: translateY(38%);
    transition-delay: 500ms;
  }
  .card:focus-within .card-content {
    transition-duration: 0ms;
  }
  .card-title::after {
    transform: scaleX(0);
  }
}

.button {
  display: inline-block;
  text-decoration: none;
  color: var(--clr-neutral-900);
  background-color: white;
  padding: 0.3em 1em;
  border-radius: 23px;
}
.button:hover,
.button:focus {
  background-color: var(--clr-neutral-100);
}

/* Form */

.form-group {
  margin-bottom: 3%;
  margin: 1%;
}
@media screen and (max-width: 1200px) {
  .form-container {
    margin: 0 8%;
    text-align: center;
  }
}
@media screen and (min-width: 1200px) {
  .form-container {
    margin: 0 14%;
    text-align: center;
  }
}

.form-group {
  margin: 0%;
}
.form-control {
  margin-bottom: 5%;
}
.btn {
  margin-bottom: 2%;
}

/* Footer */

.footer {
  max-width: 1380px;
  margin: auto;
  border-top: 1px solid rgb(182, 182, 182);
  height: 100%;
}

.contact-us p,
h2 {
  margin: 0px;
  /* font-weight: bold; */
}
.contact-us p {
  color: grey;
}

.footer-container {
  width: 80%;
  margin: 40px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-items {
  margin: auto;
  width: 100%;
  height: 200px;

  text-align: left;
}
.footer-items ul {
  padding: 0px;
  /* background-color: #f8f9fa; */
}
.footer-items h5 {
  color: black;
  /* font-weight: bold; */
}
.footer-items ul li a {
  text-decoration: none;
  color: grey;
}
.footer-items ul li a:hover {
  color: rgb(78, 78, 78);
  text-decoration: underline;
}
.social {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 360px;
  margin: 40px auto;
}
.social a {
  display: flex;

  justify-content: center;
  align-items: center;
}
.social-icons,
.contact-icons {
  font-size: 30px;
  cursor: pointer;
  transition: transform 0.2s;
  margin: 10px;
  display: inline;
}
.social-icons {
  color: black;
}
.contact-icons {
  color: grey;
  font-size: 18px;
  margin: 5px;
}
.social-icons:hover {
  transform: scale(1.5);
}
.mail-us {
  text-decoration: none;
  color: grey !important;
}
.newsletter {
  margin: auto;
  width: 100%;
  height: 200px;
}
@media (max-width: 991px) {
  .footer-container {
    display: flex;
    flex-direction: column-reverse;
    width: 95%;
  }
  .footer-address {
    text-align: center;
  }
  .footer-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  .footer-items h5 {
    font-size: 1.5rem;
  }
  .footer-items ul li a {
    font-size: 0.8rem;
  }
  .social {
    grid-template-columns: 1fr 1fr 1fr;
    width: 280px;
    margin: 40px auto;
    row-gap: 30px;
  }
  .important-footer {
    display: none;
  }
}
